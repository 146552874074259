<template>
  <div class="content-wrapper inventory-query">
    <a-spin :spinning="isLoading" style="background: rgba(0, 0, 0, 0)">
      <div class="clearfix content">
        <a-row type="flex" class="headerMsg">
          <a-col><breadcrumb></breadcrumb></a-col>
        </a-row>
        <div @keydown="enterPress">
          <a-tabs active-key="inventoryQuery" @change="selectTab">
            <a-tab-pane key="inventoryQuery" :tab="$t(`单品库存`)"></a-tab-pane>
            <a-tab-pane key="groupGoodsSearch" :tab="$t(`组合商品库存`)"></a-tab-pane>
          </a-tabs>
          <a-row :gutter="16">
            <a-col :span="6">
              <a-input
                allowClear
                :placeholder="$t(`请输入商家编码/商品条码/商品名称`)"
                v-model="searchData.keys"
              ></a-input>
            </a-col>
            <a-col :span="6">
              <!-- 仓库名称选项 -->
              <SearchHourse :placeholder="$t('请输入/选择仓库')" @selected="selectedWarehouse" />
            </a-col>
            <a-col :span="6">
              <!-- 商品类目选项 -->
              <categoryList
                :canNotAdd="true"
                @handleCategoryChange="handleCategoryChange"
              ></categoryList>
            </a-col>
            <!-- </a-row> -->
            <!-- <a-row> -->
            <a-col :span="6">
              <!-- 品牌 -->
              <a-select
                allowClear
                show-search
                style="width: 100%"
                v-model="searchData.brandId"
                :placeholder="$t(`请输入/选择品牌`)"
              >
                <a-select-option
                  v-for="item in brandNameOptions"
                  :value="item.brandId"
                  :key="item.brandName"
                >
                  {{ item.brandName }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="6" class="mt10">
              <a-select
                mode="multiple"
                v-model="searchData.excludeTypeList"
                style="width: 100%"
                :placeholder="$t('排除类型')"
              >
                <a-select-option value="sample">
                  {{ $t('寄样') }}
                </a-select-option>
                <a-select-option value="b2b">
                  {{ $t('B2B') }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col :span="12" class="mt10">
              <!-- 查询导出按钮 -->
              <a-button
                v-if="priv('f_inventory_sku_list')"
                @click="getTableList(1)"
                type="primary"
                icon="search"
              >
                {{ $t(`查询`) }}
              </a-button>
              <a-button v-if="priv('f_inventory_sku_list')" @click="showExportModal" icon="upload">
                {{ $t(`导出`) }}
              </a-button>
            </a-col>
          </a-row>
        </div>
        <!-- 顶部结束 -->
        <div class="tabs-content-wrapper">
          <!-- Main content -->
          <section style="margin-top: 1%; position: relative">
            <a-tabs
              type="card"
              defaultActiveKey=""
              @change="changeTabs"
              :tabBarGutter="tabBarGutter"
            >
              <a-tab-pane key="" :tab="$t(`全部`)"></a-tab-pane>
              <a-tab-pane key="OUT_OF_STOCK">
                <span slot="tab">
                  <a-popover placement="topLeft">
                    <template slot="content">
                      <p>{{ $t(`可用库存低于预警库存下限`) }}</p>
                    </template>
                    {{ $t(`缺货`) }}
                  </a-popover>
                </span>
              </a-tab-pane>
              <a-tab-pane key="UNSALABLE">
                <span slot="tab">
                  <a-popover placement="topLeft">
                    <template slot="content">
                      <p>{{ $t(`可用库存高于预警库存上限`) }}</p>
                    </template>
                    {{ $t(`滞销`) }}
                  </a-popover>
                </span>
              </a-tab-pane>
            </a-tabs>
            <a-table
              :columns="columns"
              :data-source="tableList"
              @change="changeSort"
              :scroll="{ x: 1800, y: tableHeight }"
              :pagination="false"
              :rowKey="(record, index) => record.barcode + ',' + index"
              bordered
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
            >
              <template slot="ProductImgContent" slot-scope="text, record">
                <img :src="record.skuImage" width="60" height="60" />
              </template>
              <template slot="ProductInfo" slot-scope="text, record">
                <div>{{ $t('名称') }}:{{ record.skuName }}</div>
                <div>{{ $t('条码') }}:{{ record.barcode }}</div>
                <div>{{ $t('编码') }}:{{ record.skuCodeList.join(',') }}</div>
              </template>
              <template slot="categoryName" slot-scope="text, record">
                {{ record.categoryList.split(',') }}
              </template>
              <template slot="brandName" slot-scope="text, record">
                <p v-for="(item, i) in record.brandList" :key="i">
                  <a-divider
                    v-if="i > 0"
                    style="width: 130px; margin-left: -16px; margin-top: 44px !important"
                  ></a-divider>
                  {{ record.brandList[i].brandName }}
                </p>
              </template>
              <template slot="warehouseName" slot-scope="text, record">
                <p v-for="(item, i) in record.warehouseStockList" :key="i">
                  <a-divider
                    v-if="i > 0"
                    style="width: 150px; margin-left: -16px; margin-top: 44px !important"
                  ></a-divider>
                  {{ record.warehouseStockList[i].warehouseName }}
                </p>
              </template>
              <template :slot="item.slot" v-for="(item, index) in tips">
                <div style="display: flex; align-items: center; font-size: 13px" :key="index">
                  <a-popover>
                    <template slot="content">
                      <span>{{ item.tip }}</span>
                    </template>
                    <span>
                      {{ item.title }}
                      <a-icon style="color: #1890ff" type="question-circle"></a-icon>
                    </span>
                  </a-popover>
                </div>
              </template>
              <template slot="lockStock" slot-scope="text, record">
                <p>{{ record.lockedStock }}</p>
              </template>
              <!-- 安全库存 -->
              <template slot="safetyQuantity" slot-scope="text, record">
                <span>
                  <p v-if="record.safetyQuantity == null">-</p>
                  <MulUnitShow
                    v-else
                    :list="record.unitList"
                    :amount="record.safetyQuantity || 0"
                  />
                </span>
              </template>
              <span
                v-for="(val, index) in mulUnitShowCols"
                :slot="val.key"
                :key="index"
                slot-scope="text, record"
              >
                <MulUnitShow
                  v-if="record[val.key] || record.platformStock?.[val.key]"
                  :list="record.unitList"
                  :amount="record[val.key] || record.platformStock?.[val.key]"
                />
                <span v-else>{{ record.platformStock?.[val.key] || record[val.key] || 0 }}</span>
              </span>
              <!-- 预警库存下限 -->
              <span slot="SafetyStockFloor" style="font-size: 13px">
                {{ $t(`下限`) }}
              </span>
              <!-- 预警库存上限 -->
              <span slot="SafetyStockUp" style="font-size: 13px">
                <span>{{ $t(`上限`) }}</span>
              </span>
              <!-- 状态 -->
              <template slot="Status" slot-scope="text, record">
                <a-tag v-if="record.stockStatus === 'NOT_SET'" color="#ccc">
                  {{ $t(`NotSet`) }}
                </a-tag>
                <a-tag v-if="record.stockStatus === 'NORMAL'" color="#3EA3FF">
                  {{ $t(`正常`) }}
                </a-tag>
                <a-tag v-if="record.stockStatus === 'OUT_OF_STOCK'" color="#FF3F3F">
                  {{ $t(`缺货`) }}
                </a-tag>
                <a-tag v-if="record.stockStatus === 'UNSALABLE'" color="#FFBE00">
                  {{ $t(`滞销`) }}
                </a-tag>
              </template>
              <!-- 历史出库 -->
              <span slot="Historyoutbound">
                {{ $t(`Historyoutbound`) }}
              </span>
              <template slot="outboundQuantity" slot-scope="text, record">
                <p>{{ record.outboundQuantity }}</p>
              </template>
            </a-table>
          </section>
        </div>
        <div class="box-footer clearfix" style="display: flex; justify-content: end">
          <pagination
            :pageNo="searchData.pageNo"
            :length="searchData.pageSize"
            :total-count="pageData.totalCount"
            @paginate="paginate"
          ></pagination>
        </div>
      </div>
    </a-spin>
    <a-modal
      :visible="showExportVisible"
      :title="$t('选择导出单位')"
      @cancel="showExportVisible = false"
      @ok="onExport"
    >
      <a-form-model>
        <a-form-model-item :label="$t('选择单位')">
          <SelectUnitType :code.sync="unitType" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import http from '@common/http'
import api from '@common/api'
import pagination from '@component/pagination'
import SearchHourse from '@component/searchHourse'
import categoryList from '@component/categoryList'
import MulUnitShow from '@/component/mulUnitShow.vue'
import SelectUnitType from '@/component/selectUnitType.vue'
var _self = {}
export default {
  name: 'inventoryQuery',
  components: {
    categoryList,
    pagination,
    SearchHourse,
    MulUnitShow,
    SelectUnitType,
  },
  data() {
    return {
      buId: '',
      mergeCode: '',
      merchantShopName: '',
      merchantCode: '',
      showText: false,
      brandNameOptions: [],
      tabBarGutter: 10,
      showTool: true,
      pageData: {
        totalCount: '',
      },
      tabList: [
        {
          tabId: 'inventoryQuery',
          tabName: this.$t(`单品库存`),
          active: true,
        },
        {
          tabId: 'groupGoodsSearch',
          tabName: this.$t(`组合商品库存`),
          active: false,
        },
      ],
      //查询
      singleWarehouse: true,
      exportCsv: '',
      showCopyHead: false,
      disabledSearch: false,
      showDetail: false,
      // sort: {},
      searchData: {
        pageNo: 1,
        pageSize: 10,
        excludeTypeList: [],
      },
      tableList: [], //查询结果
      totalCount: '', //总页数
      platformList: [], //渠道库存类型
      selectedRowKeys: [],
      tempRowKeys: [],
      isLoading: false,
      showExportVisible: false,
      unitType: 'BASE',
    }
  },
  mounted() {
    _self = this
    let currentShop = JSON.parse(localStorage.getItem('currentShop'))
    this.buId = this.$store.getters.partyId
    this.mergeCode = currentShop.uniqueCode
    this.merchantShopName = currentShop.name
    this.merchantCode = localStorage.getItem('merchantCode')
    _self.getTableList()
    _self.getBrandList()
    this.$nextTick(() => {
      window.onscroll = function () {
        if ($(window).scrollTop() > 300) {
          _self.showCopyHead = true
        } else {
          _self.showCopyHead = false
        }
      }
      this.exportCsv = api.exportBatchFile + '/Inventory'
    })
  },
  watch: {
    //检测弹出框的变化，决定是否禁用滚动条
    showDetail: function (newval) {
      if (newval) {
        //
        var top = $(document).scrollTop()
        $(document).on('scroll.unable', function () {
          top = top < 250 ? 250 : top
          $(document).scrollTop(top)
        })
      } else {
        $(document).unbind('scroll.unable')
      }
    },
  },
  methods: {
    getBrandList() {
      http({
        instance: this,
        type: 'post',
        url: api.brandList,
        data: {},
        success: (res) => {
          // console.log(res)
          this.brandNameOptions = res.result.brandList
        },
        error: (err) => {
          this.$error({
            title: this.$t(`错误`),
            content: err.msg,
          })
        },
      })
    },
    changeSort(p, f, { field, order }) {
      this.sort = {
        orderField: field || '',
        order: order || '',
      }
      // console.log(p)
      this.getTableList()
    },
    changeTabs(key) {
      this.searchData.stockStatus = key
      this.selectedRowKeys = []
      this.tempRowKeys = []
      this.$nextTick(() => {
        this.getTableList(1)
      })
    },
    getPopupContainer(trigger) {
      return trigger.parentElement
    },
    selectTab(tabId) {
      _self.$router.push(tabId)
    },
    selectedWarehouse(data) {
      // console.log(data)
      if (data.warehouseCode) {
        _self.searchData.warehouseCode = data.warehouseCode
      } else {
        _self.searchData.warehouseCode = ''
      }
    },
    selectedSku(res) {
      if (res.skuCode) {
        _self.searchData.keys = res.skuCode
      } else {
        _self.searchData.keys = ''
      }
    },
    handleCategoryChange(data) {
      if (data) {
        _self.searchData.categoryId = data.categoryId
        _self.searchData.categoryName = data.categoryName
      } else {
        _self.searchData.categoryId = ''
        _self.searchData.categoryName = ''
      }
    },
    async getTableList(first_page = false) {
      this.tableList = []
      this.disabledSearch = true
      this.searchData.buId = this.buId
      first_page && (this.searchData.pageNo = 1)
      // this.isLoading = true
      let res = await http({
        url: api.skuQuery,
        type: 'post',
        hasLoading: true,
        data: this.searchData,
      })
      let list = [...res.result.skuStockList].map((item) => {
        //带预定和缺货数量需要调用额外接口
        item.waitReservedStock = '-'
        item.shortageStock = '-'
        return item
      })
      this.tableList = list
      this.searchData.pageNo = res.result.page_no
      let channel = res.result.platformList //渠道库存
      this.platformList = [] //清空表头
      if (channel && channel.length) {
        channel.forEach((v) => {
          this.platformList.push({
            title: v.name,
            dataIndex: v.code,
            key: v.code,
            width: 100,
            slots: { title: v.code },
            scopedSlots: { customRender: v.code },
          })
        })
      }
      this.pageData.totalCount = res.result.total
      // this.isLoading = false
      this.getWaitReservedStock(list.map((item) => item.barcode))
    },
    async getWaitReservedStock(barcodeList) {
      let res = await http({
        url: api.getWaitReservedStockNum,
        data: {
          barcodeList,
        },
      })
      if (res.success) {
        let list = [...res.result.barcodeLockStock]
        this.tableList.forEach((item) => {
          let listItem = list.find(
            (elem) => elem.barcode === item.barcode && elem.warehouseCode === item.warehouseCode
          )
          if (listItem) {
            item.waitReservedStock = listItem.count
            item.shortageStock =
              listItem.count > item.availableToPromise
                ? listItem.count - item.availableToPromise
                : 0
          } else {
            item.waitReservedStock = 0
            item.shortageStock = 0
          }
        })
      }
    },
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.tableList]
      const target = dataSource.find((item) => item.key === key)
      if (target) {
        target[dataIndex] = value
        this.tableList = dataSource
      }
    },
    enterPress() {
      var e = event || window.event
      this.searchData.pageNo = 1
      if (e.keyCode == 13) {
        this.getTableList()
      }
    },
    paginate(data) {
      _self.searchData.pageNo = data.pageNo
      _self.searchData.pageSize = data.pageSize
      _self.getTableList()
    },
    // 选择导出的行
    onSelectChange(selectedRowKeys) {
      this.tempRowKeys = []
      selectedRowKeys.forEach((v) => {
        this.tempRowKeys.push(v.split(',')[0])
      })
      this.selectedRowKeys = selectedRowKeys
    },
    showExportModal() {
      this.showExportVisible = true
    },
    // 导出
    onExport() {
      let data = {}
      if (this.selectedRowKeys.length) {
        data = {
          barcodeList: [...new Set(this.tempRowKeys)],
          buId: this.buId,
        }
      } else {
        data = {
          categoryName: this.searchData.categoryName,
          categoryId: this.searchData.categoryId,
          warehouseCode: this.searchData.warehouseCode,
          keys: this.searchData.keys,
          buId: this.buId,
          brandId: this.searchData.brandId,
          excludeTypeList: this.searchData.excludeTypeList,
        }
      }
      data.unitType = this.unitType
      http({
        instance: this,
        type: 'post',
        url: api.taskCreate,
        hasLoading: true,
        data: {
          bookCode: 'singleInventoryExport',
          mergeCode: _self.mergeCode,
          merchantShopName: _self.merchantShopName,
          appCode: 'OMS',
          params: {
            merchantCode: _self.merchantCode,
            ...data,
          },
        },
        success: () => {
          this.$message.success(this.$t(`导出任务创建成功，请前往下载中心下载`))
          this.showExportVisible = false
        },
        error: (err) => {
          this.$error({
            title: this.$t(`错误`),
            content: err.msg,
          })
        },
      })
    },
    customCell(record, index) {
      let rowSpan = this.mergeCells(record['barcode'], this.tableList, 'barcode', index)
      return rowSpan > 0
        ? {
            attrs: {
              rowSpan,
            },
          }
        : {
            style: {
              display: 'none',
            },
            attrs: {
              rowSpan: 0,
            },
          }
    },
    mergeCells(text, data, key, index) {
      // 上一行该列数据是否一样
      if (index !== 0 && text === data[index - 1]?.[key]) {
        return 0
      }
      let rowSpan = 1
      // 判断下一行是否相等
      for (let i = index + 1; i < data.length; i++) {
        if (text !== data[i][key]) {
          break
        }
        rowSpan++
      }
      return rowSpan
    },
  },
  computed: {
    tips() {
      return [
        {
          slot: 'ActualInventory',
          title: this.$t('实际'),
          tip: this.$t(`仓库内现有的库存数量`),
        },
        {
          slot: 'AvailableInventory',
          title: this.$t('可用'),
          tip: this.$t(`当前可预定的库存数量`),
        },
        {
          slot: 'DefectiveInventory',
          title: this.$t('残次品'),
          tip: this.$t(`仓库内现有的残次品库存数量`),
        },
        {
          slot: 'WaitReservedInventory',
          title: this.$t('待预定'),
          tip: this.$t(`订单中未预定的商品数量`),
        },
        {
          slot: 'ShortageInventory',
          title: this.$t('缺货'),
          tip: this.$t(`缺货说明`),
        },
        {
          slot: 'InTransitInventory',
          title: this.$t('在途'),
          tip: this.$t(`预到货通知单中待入库数量合计`),
        },
        {
          slot: 'LockInventory',
          title: this.$t(`锁定`),
          tip: this.$t(`锁定库存：可用库存中，设置禁止销售的部分`),
        },
        {
          slot: 'channelInventoryTitle',
          title: this.$t(`渠道库存`),
          tip: this.$t(`分配给不同渠道的库存，计算口径为可用库存*渠道库存百分比`),
        },
        {
          slot: 'Sales',
          title: this.$t(`动销`),
          tip: this.$t(`从昨天开始倒推7、15、30天，这个商品的销售数量`),
        },
        {
          slot: 'safetyQuantityTitle',
          title: this.$t(`安全库存`),
          tip: this.$t(`安全库存：可用库存中，设置不允许预定的部分`),
        },
      ]
    },
    columns() {
      return [
        {
          title: this.$t(`商品图片`),
          width: 135,
          dataIndex: 'ProductImg',
          customCell: this.customCell,
          scopedSlots: { customRender: 'ProductImgContent' },
        },
        {
          title: this.$t(`商品信息`),
          dataIndex: 'skuName',
          scopedSlots: { customRender: 'ProductInfo' },
          customCell: this.customCell,
          width: 300,
        },
        {
          title: this.$t(`规格值`),
          width: 100,
          dataIndex: 'skuStyleList',
          customCell: this.customCell,
          customRender: (text, record) => record.skuStyleList.join(','),
        },
        {
          title: this.$t('品牌'),
          width: 100,
          dataIndex: 'brand',
          customCell: this.customCell,
          customRender: (text, record) => record.brandList.join(','),
        },
        {
          title: this.$t(`类目`),
          width: 100,
          dataIndex: 'categoryName',
          customCell: this.customCell,
          customRender: (text, record) => record.categoryList.join(','),
        },
        {
          width: 100,
          title: this.$t(`仓库`),
          key: 'warehouseName',
          dataIndex: 'warehouseName',
        },
        {
          slots: { title: 'safetyQuantityTitle' },
          key: 'safetyQuantity',
          scopedSlots: { customRender: 'safetyQuantity' },
          width: 100,
        },
        {
          slots: { title: 'channelInventoryTitle' },
          children: Array.from(this.platformList),
        },
        {
          title: this.$t('仓库库存'),
          children: [
            {
              dataIndex: 'quantityOnHand',
              key: 'quantityOnHand',
              width: 100,
              slots: { title: 'ActualInventory' },
              scopedSlots: { customRender: 'quantityOnHand' },
            },
            {
              slots: { title: 'AvailableInventory' },
              dataIndex: 'availableToPromise',
              key: 'availableToPromise',
              width: 100,
              scopedSlots: { customRender: 'availableToPromise' },
            },
            {
              slots: { title: 'DefectiveInventory' },
              dataIndex: 'defectiveQuantity',
              key: 'defectiveQuantity',
              width: 100,
              scopedSlots: { customRender: 'defectiveQuantity' },
            },
            {
              slots: { title: 'WaitReservedInventory' },
              dataIndex: 'waitReservedStock',
              key: 'waitReservedStock',
              width: 100,
              scopedSlots: { customRender: 'waitReservedStock' },
            },
            {
              slots: { title: 'ShortageInventory' },
              dataIndex: 'shortageStock',
              key: 'shortageStock',
              width: 100,
              scopedSlots: { customRender: 'shortageStock' },
            },
            {
              slots: { title: 'InTransitInventory' },
              dataIndex: 'inTransitStock',
              key: 'inTransitStock',
              width: 100,
              scopedSlots: { customRender: 'inTransitStock' },
            },
            {
              dataIndex: 'lockedStock',
              key: 'lockedStock',
              width: 100,
              slots: { title: 'LockInventory' },
              scopedSlots: { customRender: 'lockedStock' },
            },
          ],
        },
        {
          title: this.$t('预警库存'),
          children: [
            {
              title: this.$t('下限'),
              dataIndex: 'safetyStockLowerLimit',
              key: 'safetyStockLowerLimit',
              width: 100,
              scopedSlots: { customRender: 'safetyStockLowerLimit' },
            },
            {
              dataIndex: 'safetyStockUpperLimit',
              key: 'safetyStockUpperLimit',
              width: 100,
              slots: { title: 'SafetyStockUp' },
              scopedSlots: { customRender: 'safetyStockUpperLimit' },
            },
          ],
        },
        {
          slots: { title: 'Sales' },
          children: [
            {
              title: this.$t('7日'),
              dataIndex: 'sevenDaysSales',
              width: 100,
              customCell: this.customCell,
              scopedSlots: { customRender: 'sevenDaysSales' },
            },
            {
              title: this.$t('15日'),
              dataIndex: 'fifteenDaysSales',
              width: 100,
              customCell: this.customCell,
              scopedSlots: { customRender: 'fifteenDaysSales' },
            },
            {
              title: this.$t('30日'),
              dataIndex: 'thirtyDaysSales',
              width: 100,
              customCell: this.customCell,
              scopedSlots: { customRender: 'thirtyDaysSales' },
            },
          ],
        },
      ]
    },
    tableHeight() {
      return (window.innerHeight - 450) * this.$store.state.rate
    },
    mulUnitShowCols() {
      let list = [
        ...this.platformList.map((item) => item.key),
        'quantityOnHand',
        'availableToPromise',
        'defectiveQuantity',
        'waitReservedStock',
        'shortageStock',
        'inTransitStock',
        'lockedStock',
        'sevenDaysSales',
        'fifteenDaysSales',
        'thirtyDaysSales',
      ]
      // console.log('list: ', list)
      return list.map((item) => ({
        key: item,
      }))
    },
  },
}
</script>

<style scoped>
::v-deep .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  min-width: 100px;
  text-align: center;
}
.headerMsg {
  display: flex;
}
.maxWidth {
  max-width: 380px !important;
}

.redText {
  color: red;
}
#copyTableHead {
  position: fixed;
  top: 50px;
  z-index: 999;
}

td,
th {
  padding: 2px !important;
}
.wordBreak {
  word-break: break-all;
  max-width: 400px !important;
}
input[type='checkbox'],
i {
  cursor: pointer;
}
a {
  white-space: nowrap;
}
.col-md-3 {
  padding-left: 0px;
  padding-right: 0px;
}
.noBRborder {
  border-bottom: 1px solid #ffffff;
  border-right: 1px solid #ffffff;
}
.noBborder {
  border-bottom: 1px solid #ffffff;
}
.noB {
  border-bottom: 1px solid #ffffff;
}
.noR {
  border-right: 1px solid #ffffff;
}
.bTop {
  border-top: 1px solid #cccccc;
}
th {
  text-align: center;
}
.myRow {
  margin-bottom: 15px;
}
.myRow .control-label {
  text-align: center;
  margin-top: 5px;
}
.myRow .input-sm {
  width: 200px;
}
.pd10 {
  height: 30px;
}
::v-deep .ant-table-thead > tr > th {
  padding: 5px;
  text-align: center;
}
::v-deep .ant-table-tbody > td {
  padding: 5px;
  text-align: center;
}
::v-deep .ant-tag {
  margin-right: 20px;
}
.display_none {
  display: none;
}
</style>
<style>
.ant-popover-arrow {
  border-color: rgba(0, 0, 0, 0.7) !important;
  border-top-color: transparent !important;
  border-left-color: transparent !important;
}
.ant-popover-inner {
  background-color: rgba(0, 0, 0, 0.7);
}
.ant-popover-inner-content span {
  color: #fff;
  font-weight: bold;
  white-space: pre-line;
}
.inventory-query .ant-tabs-bar {
  margin: 0 0 8px 0;
}
</style>
